<!-- 通讯录 -->
<template>
  <div class="address-book-page">
    <van-cell class="title-box">
      <p class="title">通讯录</p>
      <template #right-icon>
        <van-icon name="add" color="#8ab1f1" size="0.45rem" @click="addUser" />
      </template>
    </van-cell>
    <div class="row">
      <van-field
        class="search-name"
        v-model.trim="userName"
        placeholder="联系人搜索"
      >
        <template #button>
          <van-button icon="search" size="small" @click="searchContact">
          </van-button
        ></template>
      </van-field>
      <van-button class="btn" type="text" round @click="addGroup"
        >新建分组</van-button
      >
    </div>
    <div v-if="groupData && groupData.length > 0">
      <van-collapse
        v-if="!listQuery.userName"
        v-model="activeGroup"
        @change="changeGroup"
        accordion
      >
        <van-collapse-item
          v-for="(group, g) in groupData"
          :key="g"
          :title="group.name"
          :name="group.groupCode"
        >
          <div v-if="list && list.length > 0">
            <van-cell
              v-for="(item, i) in list"
              :key="i"
              @click="goDetail(item)"
            >
              <template #title>
                <span>{{ item.userName }}</span>
                <span class="link"> &lt;{{ item.email }} &gt;</span>
              </template>
            </van-cell>
          </div>
          <van-empty v-else description="分组下没有联系人" />
        </van-collapse-item>
      </van-collapse>
      <div v-else>
        <van-cell
          v-for="(concat, c) in concatList"
          :key="c"
          @click="goDetail(concat)"
          is-link
        >
          <template #title>
            <span>{{ concat.userName }}</span>
            <span class="link"> &lt;{{ concat.email }} &gt;</span>
          </template>
        </van-cell>
      </div>
    </div>
    <van-empty v-else description="没有通讯录分组" />
  </div>
</template>

<script>
import { groupList, getAddressBookPageList } from "@/api/email";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      list: [],
      userName: "",
      listQuery: {
        userName: "",
        pageNum: 1,
        pageSize: 10,
        groupCode: "",
      },
      groupData: [],
      activeGroup: "DEFAULTS_GROUP",
      show: false,
      concatList: null,
    };
  },
  watch: {
    userName(val) {
      if (!val) {
        this.clearUserName();
      }
    },
  },
  components: {},

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    searchContact() {
      this.listQuery.pageNum = 1;
      this.listQuery.groupCode = "";
      this.listQuery.userName = this.userName;
      getAddressBookPageList(this.listQuery).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200 && data) {
            if (this.listQuery.pageNum == 1) {
              this.concatList = res.data.records;
            }
          }
        }
      });
    },
    clearUserName() {
      this.listQuery.pageNum = 1;
      this.userName = "";
      this.listQuery.userName = "";
      if (this.groupData.length) {
        this.activeGroup = this.groupData[0].groupCode;
        this.listQuery.groupCode = this.activeGroup;
        this.getContactList();
      }
    },
    //分组
    getList() {
      groupList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.groupData = data;
            if (data && data.length > 0) {
              this.activeGroup = data[0].groupCode;
              this.listQuery.groupCode = this.activeGroup;
              this.getContactList();
            }
          }
        }
      });
    },
    getContactList() {
      getAddressBookPageList(this.listQuery).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200 && data) {
            this.total = data.total;
            if (this.listQuery.pageNum == 1) {
              this.list = res.data.records;
            } else {
              this.list = this.list.concat(res.data.records);
            }
            this.listQuery.pageNum++;
            if (this.listQuery.pageNum >= data.pages) {
              this.finished = true;
            }
          }
        }
      });
    },
    changeGroup(val) {
      this.listQuery.pageNum = 1;
      this.activeGroup = val;
      this.listQuery.groupCode = val;
      this.getContactList();
    },
    goDetail(item) {
      this.$router.push(`/EmailContactDetail/${item.bizCode}`);
    },
    onLoad() {
      // if (this.activeGroup) {
      //   this.getContactList();
      // }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    addUser() {
      this.$router.push("/addEmailContact");
    },

    addGroup() {
      this.$router.push("/addEmailGroup");
    },
  },
};
</script>
<style lang="scss" scoped>
.address-book-page {
  width: 100%;
  min-height: 100%;
  .title-box {
    background: #f0f6ff;

    .title {
      text-align: center;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    .search-name {
      background: #f0f0f0;
      flex: 1;
    }
    .btn {
      margin-left: 0.1rem;
      width: 2rem;
    }
    .link {
      color: #0094ee;
    }
  }
}
</style>
